/** @format */

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from 'src/components/home';
import styles from './workArea.module.scss';
import Questions from 'src/components/questions';
import Order from 'src/components/order';
import OrderResult from 'src/components/orderRequest';
import About from 'src/components/about';
import TestPage from 'src/components/views/testpage';

export default function WorkArea() {
  return (
    <div className={styles.workArea}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/questions/:code" element={<Questions key={'code'} />} />
        <Route path="/order/:code" element={<Order key={'code'} />} />
        <Route path="/orderRequest" element={<OrderResult/>} />
        <Route path="/About" element={<About/>} />
        <Route path="/VGVzdFBhZ2U=" element={<TestPage/>} />
      </Routes>
    </div>
  );
}
