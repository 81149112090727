import { Typography } from '@mui/material';
import React from 'react';
import styles from './questions.module.scss';
import QuestionControl from './questionControl';

export const getDataForUnits = (jsonRule, measurementUnits) => {
  let parsedJson;
  try {
    parsedJson = JSON.parse(jsonRule);
  } catch (error) {
    parsedJson = [[], [], []];
  }

  const parsedUnits = parsedJson[1] ?? [];
  const rules = parsedJson[2];
  const units = parsedUnits.length
    ? parsedUnits
        .map((unitId) => measurementUnits.find((i) => i.id === unitId))
        .filter((i) => i)
        .map((unitData, i) => ({
          ...unitData,
          unitRules: rules[i],
          descendants: parsedJson[0],
        }))
    : [];

  return units;
};

export default function Question({
  number,
  uuid,
  description,
  type,
  jsonRule,
  descendants,
  measurements,
  onValueChanged,
  data,
}) {
  const { value, min, max, currentUnit } = data || {};

  const units = getDataForUnits(jsonRule, measurements);
  // let parsedJson;
  // try {
  //   parsedJson = JSON.parse(jsonRule);
  // } catch (error) {
  //   parsedJson = [[], [], []];
  // }

  const availableValues = descendants
    .filter((i) => i)
    .map((i) => ({ title: i.description || '', value: i.uuid }));
  // const parsedUnits = parsedJson[1] ?? [];
  // const rules = parsedJson[2];
  // const units = parsedUnits.length
  //   ? parsedUnits
  //       .map((unitId) => measurements.find((i) => i.id === unitId))
  //       .filter((i) => i)
  //       .map((unitData, i) => ({
  //         ...unitData,
  //         unitRules: rules[i],
  //         descendants: parsedJson[0],
  //       }))
  //   : [];

  const currentUnitFilledWithRules = units.find(
    (i) => i.id === currentUnit?.id
  );

  const handlerControlChange = (valueType, val) => {
    const data = {
      value: value,
      min: min,
      max: max,
      currentUnit: currentUnitFilledWithRules,
      uuid: uuid,
      type: type,
    };

    switch (valueType) {
      case 'value':
        data.value = val;
        break;
      case 'min':
        data.min = val;
        break;
      case 'max':
        data.max = val;
        break;
      case 'unit':
        data.currentUnit = val;
        break;
      default:
        break;
    }

    onValueChanged(data);
  };

  return (
    <div className={styles.question}>
      <Typography variant="h6" pb={2}>
        {description}
      </Typography>
      <QuestionControl
        type={type}
        value={value}
        availableValues={availableValues}
        setValue={(val) => handlerControlChange('value', val)}
        min={min}
        setMin={(val) => handlerControlChange('min', val)}
        setMax={(val) => handlerControlChange('max', val)}
        max={max}
        currentUnit={currentUnitFilledWithRules}
        setCurrentUnit={(val) => handlerControlChange('unit', val)}
        units={units}
      />
    </div>
  );
}
