import AbortController from "abort-controller"

export type setFetchCancell = (...args: [AbortController]) => void
// Fetch and return the promise with the abort controller as controller property
export function fetchWithController(
  url: string, 
  init: object, 
  setCancelCallback?: (setFetchCancell | null))
    : Promise<any> 
{
  const controller = new AbortController()
  const signal = controller.signal
  const promise = fetch(url, {...init, signal: signal as any})
  setCancelCallback && setCancelCallback(controller)
  return promise
}