import React, { useEffect, useRef } from 'react';
import styles from './questions.module.scss';
import useApi from 'src/Api/useApi';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Paper, Skeleton, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Question, { getDataForUnits } from './question';
import PendingRows from 'src/components/ui/pendingRows';
import { Box } from '@mui/system';
import OrderData from 'src/components/order/orderData';
import FileUpload from '../ui/fileUpload';
import { setError } from 'src/redux/error';
import { useDispatch } from 'react-redux';

const panels = ['existed', 'manual', 'fileImport'];

export default function Questions() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { code } = params || {};
  const { api, inProgress } = useApi();
  const [questions, setQuestions] = useState([]);
  const [space, setSpace] = useState('');
  const [units, setUnits] = useState([]);
  const [expanded, setExpanded] = useState(panels[1]);
  const [fileHandled, setFileHandled] = useState(false);
  const [allResultData, setResultData] = useState({ values: [], space: code });
  const [baseCode, setBaseCode] = useState('');
  const [notUsedOrderData, setNotUsedOrderData] = useState([]);
  const cancelQuestions = useRef(null);
  const cancelSpaces = useRef(null);
  const cancelOrder = useRef(null);

  const onFileError = (err) => {
    dispatch(setError({ message: err }));
  };

  const onPanelClick = (name) => {
    setExpanded(expanded === name ? '' : name);
  };

  const onUploadFinish = (result) => {
    setFileHandled(true);
    console.log(result);
    const { measurements, questions } = result;
    const questionsWithRecognizedData = questions
      .filter((q) => !!q.recognizedData)
      .reduce((acc, cur) => {
        const units = getDataForUnits(cur.jsonRule, measurements);
        const currentUnit = units.find(
          (u) => u.id === cur.recognizedData.mUnitId
        );
        const evaluatedValue = processQuestionData({
          value: cur.recognizedData.value,
          min: cur.recognizedData.min,
          max: cur.recognizedData.max,
          currentUnit: currentUnit,
          uuid: cur.uuid,
          type: cur.type,
        });

        return [...acc, evaluatedValue];
        // return {
        //   ...acc,
        //   [i.uuid] : {
        //     ...cur,
        //     units: getDataForUnits(cur.jsonRule, measurements)
        //   }
        // }
      }, []);

    // const units = Object.values(questionsWithRecognizedData).map();
    // getDataForUnits
    // processQuestionData
    setResultData({
      ...allResultData,
      values: questionsWithRecognizedData,
    });
  };

  useEffect(() => {
    if (!code) {
      return;
    }

    const local = async () => {
      const result = await api('analysis/questions', code, {
        setCancelCallback: (callback) => (cancelQuestions.current = callback),
      });
      const spaces = await api('analysis/spaces', null, {
        setCancelCallback: (callback) => (cancelSpaces.current = callback),
      });
      const { questions, measurements } = result || {};
      setQuestions(questions ?? []);
      setUnits(measurements ?? []);
      setSpace(spaces?.find((i) => i.id === code)?.name || '');
    };
    local();

    return () => {
      cancelQuestions?.current && cancelQuestions.current.abort();
      cancelSpaces?.current && cancelSpaces.current.abort();
      cancelOrder?.current && cancelOrder.current.abort();
    };
  }, []);

  const mapToRequestData = (data) => {
    return {
      space: code,
      values: data.map((i) => {
        let value, currentUnit;
        switch (i.type) {
          case 1:
          case 2:
            value = i.value;
            currentUnit = {
              name: i.unitData?.description,
              id: i.unitData?.unitId,
            };
            break;
          case 3:
            value = {
              title: i.answerSEValue?.description,
              value: i.answerSEValue?.seValue,
            };
            break;
          case 4:
            value = i.answerSEValues?.map((v) => ({
              title: v.description,
              value: v.seValue,
            }));
            break;
        }

        return {
          uuid: i.questionId,
          value: value,
          min: i.min,
          max: i.max,
          unit: i.unitData?.unitId,
          SEResult: i.answerSEValue?.seValue,
          SEResults: i.answerSEValues?.map((v) => v.seValue),
          type: i.type,
          colorCode: i.colorCode,
          currentUnit: currentUnit,
        };
      }),
    };
  };

  const handleAnalysis = async () => {
    const { values = [] } = allResultData || {};
    const validData = values.filter(
      (i) => i.SEResult || (i.SEResults && i.SEResults.length)
    );
    // для правильной десериализации данных на сервере
    validData.forEach((i) => {
      if (i.type === 2 || i.type === 1) {
        delete i.currentUnit;
      }
      if (i.type === 3 || i.type === 4) {
        delete i.value;
      }
    });

    const resp = await api('analysis/create', {
      ...allResultData,
      values: validData,
    });
    const { code } = resp || {};
    code && history(`/order/${code}`);
  };

  const processQuestionData = (data) => {
    const { value, min, max, currentUnit, uuid, type } = data;
    const { id, unitRules, descendants = [] } = currentUnit || {};
    let result, ruleIndex;

    switch (Number(type)) {
      case 1:
        ruleIndex = calculateFixRulesIndex(value, unitRules);
        result = {
          value: value,
          unit: id,
          SEResult: ruleIndex >= 0 ? descendants[ruleIndex] : undefined,
          colorCode:
            ruleIndex >= 0 && unitRules ? unitRules[ruleIndex][2] : undefined,
        };
        break;
      case 2:
        ruleIndex = calculateFixRulesIndex(
          value,
          normalizeRules(min, max, unitRules)
        );
        result = {
          value: value,
          min: min,
          max: max,
          unit: id,
          SEResult: ruleIndex >= 0 ? descendants[ruleIndex] : undefined,
          colorCode:
            ruleIndex >= 0 && unitRules ? unitRules[ruleIndex][2] : undefined,
        };
        break;
      case 3:
        result = {
          value: value,
          SEResult: value?.value,
        };
        break;
      case 4:
        result = {
          value: value,
          SEResults: value?.map((i) => i.value),
        };
        break;
      default:
        break;
    }

    return {
      ...result,
      uuid,
      type,
      currentUnit,
    };
  };

  const onValueChanged = (data) => {
    // const {uuid, type, currentUnit} = data
    const result = processQuestionData(data);
    const { values } = allResultData || {};
    // const { value, min, max, currentUnit, uuid, type } = data;
    // const { id, unitRules, descendants = [] } = currentUnit || {};
    // const { values } = allResultData || {};
    // let result, ruleIndex;

    // switch (Number(type)) {
    //   case 1:
    //     ruleIndex = calculateFixRulesIndex(value, unitRules);
    //     result = {
    //       value: value,
    //       unit: id,
    //       SEResult: ruleIndex >= 0 ? descendants[ruleIndex] : undefined,
    //       colorCode:
    //         ruleIndex >= 0 && unitRules ? unitRules[ruleIndex][2] : undefined,
    //     };
    //     break;
    //   case 2:
    //     ruleIndex = calculateFixRulesIndex(
    //       value,
    //       normalizeRules(min, max, unitRules)
    //     );
    //     result = {
    //       value: value,
    //       min: min,
    //       max: max,
    //       unit: id,
    //       SEResult: ruleIndex >= 0 ? descendants[ruleIndex] : undefined,
    //       colorCode:
    //         ruleIndex >= 0 && unitRules ? unitRules[ruleIndex][2] : undefined,
    //     };
    //     break;
    //   case 3:
    //     result = {
    //       value: value,
    //       SEResult: value?.value,
    //     };
    //     break;
    //   case 4:
    //     result = {
    //       value: value,
    //       SEResults: value?.map((i) => i.value),
    //     };
    //     break;
    //   default:
    //     break;
    // }

    setResultData({
      ...allResultData,
      values: [
        ...values.filter((i) => i.uuid !== result.uuid),
        result,
        // {
        //   ...result,
        //   type,
        //   uuid,
        //   currentUnit: currentUnit,
        // },
      ],
    });
  };

  const requestAnalysis = async () => {
    const orderData = await api('analysis/order', baseCode, {
      setCancelCallback: (callback) => (cancelOrder.current = callback),
    });

    const { notConditional } = orderData;
    if (notConditional && notConditional.length) {
      const questionMap = questions.reduce((acc, cur) => {
        acc[cur.uuid] = cur;
        return acc;
      }, {});

      const filteredBySpace = notConditional.filter(
        (i) => !!questionMap[i.questionId]
      );
      const notUsedValues = notConditional.filter(
        (i) => !questionMap[i.questionId]
      );
      setNotUsedOrderData(notUsedValues.filter((i) => i.type !== 0));
      setResultData(mapToRequestData(filteredBySpace));
    }
  };

  return (
    <Paper className={styles.paper}>
      <Typography variant="h6" pl={3} pt={3}>
        Вопросы программы
      </Typography>
      {space && (
        <Typography variant="h5" pb={2} pl={3}>
          {space}
        </Typography>
      )}
      {inProgress && (
        <Typography variant="h3" pb={2} pl={3} pr={3}>
          <Skeleton />
        </Typography>
      )}
      <Divider variant="fullWidth" />
      {questions?.length ? (
        <Accordion
          expanded={expanded === panels[0]}
          onChange={() => onPanelClick(panels[0])}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography pl={1}>Данные существующего запроса</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <TextField
                fullWidth
                label="Код запроса"
                value={baseCode}
                onChange={(e) => {
                  setBaseCode(e.target.value);
                }}
              ></TextField>
            </Box>
            <Box pt={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={!baseCode}
                onClick={requestAnalysis}
              >
                Взять за основу данные обработанного ранее запроса
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Box pl={3} pr={3}>
        <PendingRows inProgress={inProgress} />
      </Box>
      {!questions?.length && !inProgress && (
        <Typography style={{ textAlign: 'center' }} variant="h6">
          Данные отсутствуют
        </Typography>
      )}
      {!inProgress && (
        <Accordion
          expanded={expanded === panels[1]}
          className={styles.dataAccordeon}
          onChange={() => onPanelClick(panels[1])}
          // disableGutters
          TransitionProps={{ timeout: { appear: 1, enter: 1, exit: 4 } }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" pl={1}>
              {fileHandled
                ? 'Результат обработки файла'
                : 'Внесение данных вручную'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant="fullWidth" />
            <div className={styles.container}>
              {!inProgress &&
                questions.map((i) => (
                  <Question
                    {...i}
                    key={i.uuid}
                    measurements={units}
                    data={allResultData?.values?.find((v) => v.uuid === i.uuid)}
                    onValueChanged={onValueChanged}
                  />
                ))}
            </div>
            <Box sx={{ display: 'flex' }} p={3}>
              <Button
                variant="contained"
                onClick={handleAnalysis}
                disabled={inProgress || !questions || !questions.length}
                sx={{ flexGrow: '1' }}
              >
                анализ
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {questions.length && notUsedOrderData?.length ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography pl={1}>
              Данные, которые не используются в текущей программе
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OrderData inProgress={inProgress} orderData={notUsedOrderData} />
          </AccordionDetails>
        </Accordion>
      ) : null}
      {/* {!inProgress && ( */}
      {false && (
        <Accordion
          expanded={expanded === panels[2]}
          onChange={() => onPanelClick(panels[2])}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant={'h6'} pl={1}>
              Импорт данных из файла
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant="fullWidth" />
            <Typography variant={'body2'} p={1} pt={3}>
              Выберите файл с расширением PDF, размер не должен превышать 8 Mб.
            </Typography>
            <Box pt={3}>
              <FileUpload
                url={'file/handleAnalysisFile'}
                onUploadFinish={onUploadFinish}
                // onUploadStart={onUploadStart}
                maxFileSize={3}
                // allowedExtentions={['.pdf']}
                onError={onFileError}
                additionalData={[{ code: 'spaceId', value: code }]}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {/* <Divider variant="fullWidth" /> */}
    </Paper>
  );
}

/**
 * Получение индекса подходящего правила из списка правил преобразования.
 * На основе полученного индекса получаем uuid СЕ из списка потомков
 * глобальной интеграции (ГИ - то же, что и вопрос, поле потомков -
 * "question.descendants")
 * @param {number} value Значение параметра
 * @param {Array<rules>} rules правила преобразования в СЕ
 * @returns {Array<rules>} индекс подходящего правила
 */
function calculateFixRulesIndex(value, rules) {
  let result = -1;
  if (!value || !rules) {
    return result;
  }

  try {
    for (const [i, rule] of rules.entries()) {
      if (
        (rule[1] === true && Number(value) <= Number(rule[0])) ||
        (rule[1] === false && Number(value) < Number(rule[0])) ||
        rule[1] === 'max'
      ) {
        result = i;
        break;
      }
    }
  } catch (error) {
    console.error('calculateFixRules', value, rules);
    console.error('calculateFixRules', error);
  } finally {
    return result;
  }
}

/**
 * Преобразование правила к абсолютным единицам на основе входных данных.
 * Последнее правило в списке не меняется
 * @param {number} min нижняя граница
 * @param {number} max верхняя граница
 * @param {Array<rules>} rules правила преобразования в СЕ
 * @returns {Array<rules>} правила с абсолютными значениями
 */
function normalizeRules(min, max, rules) {
  if (!rules || !rules[1] || !max || !min || Number(max) <= Number(min)) {
    return null;
  }

  try {
    const normalizedRules = JSON.parse(JSON.stringify(rules));
    const rulesToChange = normalizedRules.slice(0, normalizedRules.length - 1);
    const delta = Number(max) - Number(min);
    for (const rule of rulesToChange) {
      rule[0] = Number(min) + (delta * Number(rule[0])) / 100;
    }
    return normalizedRules;
  } catch (error) {
    console.error('normalizeRules', min, max, rules);
    console.error('normalizeRules', error);
    return null;
  }
}
