import React, { useEffect, useRef, useState } from 'react';
import styles from './test.module.scss';
import useApi from 'src/Api/useApi';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import FileUpload from 'src/components/ui/fileUpload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TestPage = () => {
  const { api, inProgress } = useApi();

  const [localData, setLocalData] = useState({
    0: {
      fileName: 'Название файла',
      id: 'Идентификатор',
      mode: 'Модель',
      result: 'результат',
    },
  });
  const [modeValue, setModeValue] = useState({
    title:
      'Изображения с любым количеством строк текста, сверстанного в одну колонку (по умолчанию)',
    value: 'page',
  });
  const [recognitionResult, setRecognitionResult] = useState();
  const [currentInfo, setCurrentInfo] = useState(null);
  const [autocompleteInput, setAutocompleteInput] = useState('');
  const [recognitionOperationId, setRecognitionOperationId] = useState('');
  // const recognitionOperationId = useRef(null);

  useEffect(() => {
    const data = localStorage.getItem('pdfRecognitionData');
    data &&
      setLocalData(JSON.parse(localStorage.getItem('pdfRecognitionData')));
  }, []);

  const onGetResult = async () => {
    const result = await api(
      'file/getPdfResult',
      recognitionOperationId
    );

    const { recognitionResult } = result ?? {};

    if (!!recognitionResult?.error) {
      if (recognitionResult?.error?.httpCode === 404) {
        setRecognitionResult(
          `Не найден результат операции: ${recognitionResult?.error?.message}`
        );
      } else {
        setRecognitionResult(recognitionResult?.error?.message);
      }
      return;
    }

    if (recognitionResult && !recognitionResult.error) {
      const resultJson = JSON.stringify(result);
      setRecognitionResult(resultJson);

      // recognitionOperationId.current = null;
      if (!currentInfo) {
        return 
      }

      setCurrentInfo(null);
      // const savedData =
      // JSON.parse(localStorage.getItem('pdfRecognitionData')) ?? {};
      const updatedData = {
        ...localData,
        [recognitionOperationId]: {
          ...currentInfo,
          // result: resultJson,
          id: recognitionOperationId,
        },
      };
      setLocalData(updatedData);
      localStorage.setItem('pdfRecognitionData', JSON.stringify(updatedData));
    }
  };

  const onUploadFinish = async (result) => {
    if (!result) {
      return;
    }

    setRecognitionResult(JSON.stringify(result));
  };

  const onUploadStart = (files) => {
    setCurrentInfo({ fileName: files?.[0]?.name, mode: modeValue.value });
  };

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
  };
  const handleResultCopyClick = () => {
    navigator.clipboard.writeText(recognitionResult);
  };

  return (
    <Paper className={styles.paper}>
      <Typography variant="h6" p={3}>
        Обработать данные из файла
      </Typography>
      <Divider variant="fullWidth" />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography pl={1}>Предыдущие запросы</Typography>
        </AccordionSummary>
        {localData && (
          <AccordionDetails>
            {Object.values(localData).map((value, i) => {
              return (
                <Box className={styles.savedData}>
                  <Typography variant="body1" p={1}>
                    {value.fileName}
                  </Typography>
                  <Typography variant="body1" p={1}>
                    {value.mode}
                  </Typography>
                  <Typography variant="body1" p={1}>
                    {value.id}
                    {value.id !== 'Идентификатор' && (
                      <IconButton
                        onClick={() => handleCopyClick(value.id)}
                        color="primary"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    )}
                  </Typography>
                </Box>
              );
            })}
          </AccordionDetails>
        )}
      </Accordion>
      <Divider variant="fullWidth" />
      <Box p={3}>
        <Autocomplete
          value={modeValue || null}
          onChange={(event, newValue) => {
            setModeValue(newValue);
          }}
          inputValue={autocompleteInput}
          onInputChange={(event, newInputValue) => {
            setAutocompleteInput(newInputValue);
          }}
          options={[
            {
              title:
                '(page) Изображения с любым количеством строк текста, сверстанного в одну колонку',
              value: 'page',
            },
            {
              title: '(page-column-sort) Многоколоночный текст',
              value: 'page-column-sort',
            },
            {
              title:
                '(handwritten) Произвольное сочетание печатного и рукописного текста на русском и английском языках',
              value: 'handwritten',
            },
            {
              title: '(table) Таблицы на русском и английском языках',
              value: 'table',
            },
          ]}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField {...params} label="Модели для распознавания текста" />
          )}
          disabled
        />
        <Box pt={3}>
          <FileUpload
            url={'file/handleAnalysisFile2'}
            onUploadFinish={onUploadFinish}
            onUploadStart={onUploadStart}
            additionalData={[{ code: 'mode', value: modeValue.value }]}
          />
        </Box>
      </Box>
      {/* <Box p={3}>
        <Button
          variant="contained"
          onClick={onGetResult}
          disabled={inProgress || !recognitionOperationId}
        >
          Запросить результат распознавания
          {inProgress && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Button>
      </Box> */}
      <Divider variant="fullWidth" />
      {/* <Typography variant="body1" p={3}>
        Идентификатор операции
      </Typography> */}
      {/* <Box p={3}>
        <TextField
          fullWidth
          label="Идентификатор операции"
          value={recognitionOperationId}
          onChange={(e) => {
            setRecognitionOperationId(e.target.value);
          }}
        ></TextField>
      </Box> */}
      <Divider variant="fullWidth" />
      <Typography variant="body1" p={3}>
        Результат распознавания
        <IconButton
          onClick={handleResultCopyClick}
          color="primary"
          disabled={!recognitionResult}
        >
          <ContentCopyIcon />
        </IconButton>
      </Typography>
      <Box p={3}>{recognitionResult}</Box>
    </Paper>
  );
};

export default TestPage;
